"use client";

import { Calendar as CalendarIcon } from "lucide-react";
import { forwardRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@libs/utils";
import dayjs, { Dayjs } from "dayjs";

import { formatDate } from "@helpers/datetime";

type DatePickerProps = {
  value?: string;
  withPresets?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
  min?: Date;
  max?: Date;
};

const PRESET_OPTIONS = [
  { value: 0, label: "Today" },
  { value: 1, label: "Tomorrow" },
  { value: 3, label: "In 3 days" },
  { value: 7, label: "In a week" },
  { value: 30, label: "In a month" },
] as const;

const TODAY = new Date();

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  ({ value = TODAY, withPresets = false, onChange, min, max, disabled }, ref) => {
    const date = dayjs(value).toDate();

    const [open, setOpen] = useState(false);
    const [month, setMonth] = useState(date);

    const formattedDate = formatDate(date, "MM/DD/YYYY");

    const disabledDates = (value: Date) => {
      if (max && dayjs(value).isAfter(dayjs(max))) return true;
      if (min && dayjs(value).isBefore(dayjs(min))) return true;
      return false;
    };

    const handlePresetChange = (value: string) => {
      const days = Number(value);
      if (Number.isNaN(days)) return;

      const newDate = dayjs().add(days, "day");
      if (disabledDates(newDate.toDate())) return;

      onSelectDate(newDate);
    };

    const handleDateChange = (value: Date | undefined) => {
      onSelectDate(dayjs(value));
    };

    const onSelectDate = (date: Dayjs) => {
      const newDate = dayjs(date).toDate();
      onChange(formatDate(newDate, "YYYY-MM-DD"));
      setMonth(newDate);
      setOpen(false);
    };

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant="outline"
            aria-label="Pick date"
            aria-expanded={open}
            aria-haspopup="true"
            className={cn(
              "w-full justify-between font-normal h-[52px] px-4 gap-2",
              !date && "text-muted-foreground"
            )}
          >
            {formattedDate}
            <CalendarIcon size={18} className="text-muted-foreground" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex w-auto flex-col space-y-2 p-2">
          {withPresets && (
            <Select onValueChange={handlePresetChange}>
              <SelectTrigger aria-label="Select preset date">
                <SelectValue placeholder="Select a preset" />
              </SelectTrigger>
              <SelectContent position="popper">
                {PRESET_OPTIONS.map(option => (
                  <SelectItem key={`preset_${option.value}`} value={option.value.toString()}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          <Calendar
            mode="single"
            month={month}
            selected={date}
            defaultMonth={date}
            onMonthChange={setMonth}
            disabled={disabledDates}
            onSelect={handleDateChange}
          />
        </PopoverContent>
      </Popover>
    );
  }
);
