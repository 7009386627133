"use client";

import dynamic from "next/dynamic";
import { forwardRef, useEffect } from "react";

const ReactQuill = dynamic(async () => import("react-quill"), { ssr: false });

interface RichTextEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  theme?: "snow" | "bubble";
}

const modules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
    handlers: {
      link: function (value: boolean) {
        // @ts-expect-error - ReactQuill types are incomplete
        const quill = this.quill;
        if (value) {
          const tooltip = quill.theme.tooltip;
          tooltip.edit("link");
          const input = tooltip.root.querySelector("input[data-link]");
          if (input) {
            input.setAttribute("placeholder", "Add url");
          }
        } else {
          quill.format("link", false);
        }
      },
    },
  },
};

export const RichTextEditor = forwardRef<HTMLDivElement, RichTextEditorProps>(
  ({ value = "", onChange, theme = "snow", placeholder = "Start writing..." }, ref) => {
    useEffect(() => {
      import(`react-quill/dist/quill.${theme}.css`);
    }, [theme]);

    return (
      <div data-rich-text>
        <ReactQuill
          theme={theme}
          value={value}
          modules={modules}
          onChange={onChange}
          placeholder={placeholder}
          bounds="[data-rich-text]"
          className="rich-text-editor [&_.ql-container]:text-base md:[&_.ql-container]:text-sm"
        />
      </div>
    );
  }
);
