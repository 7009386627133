import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const DEFAULT_TIMEZONE = "America/New_York";
const DEFAULT_FORMAT = "MM/DD/YYYY HH:mm";

export function formatDate(date: Date, format = DEFAULT_FORMAT) {
  return dayjs(date).format(format);
}

type ConvertToTimezoneProps = {
  date: Date;
  format?: string;
  timezone?: string;
  keepLocalTime?: boolean;
};

export function convertToTimezone({
  date,
  keepLocalTime = false,
  timezone = DEFAULT_TIMEZONE,
}: ConvertToTimezoneProps) {
  return dayjs(date).tz(timezone, keepLocalTime);
}

type ConvertFromUTCProps = {
  date: Date;
  format?: string;
  timezone?: string;
  keepLocalTime?: boolean;
};

export function convertFromUTC({
  date,
  keepLocalTime = false,
  format = DEFAULT_FORMAT,
  timezone = DEFAULT_TIMEZONE,
}: ConvertFromUTCProps) {
  return dayjs(date).utc().tz(timezone, keepLocalTime).format(format);
}
